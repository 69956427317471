.contenedor-mapa {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 16px;
    padding: 24px;
}

.contenedor-mapa__detalles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: inherit;
}

.contenedor-mapa__detalles__titulo {
    font-weight: var(--font-weight-bold);
    font-size: 20px;
}

.contenedor-mapa__detalles__subtitulo {
    color: var(--color-text-light);
    font-weight: var(--font-weight-regular);
    font-size: 16px;
}

.contenedor-mapa__vista {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: inherit;
}

.contenedor-mapa__vista img {
    width: 100%;
    max-width: 1248px;
    height: fit-content;
    max-height: 784px;
    border-radius: inherit;
}