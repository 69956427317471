
.card-detalle {
    width: 100%;
    border-radius: var(--border-radius-xl);
    padding: var(--padding-md);
    border: 1px solid var(--color-border);
    display: grid;
    gap: 8px;
}

.card-detalle--sm {
    width: 180px;
}

.card-detalle__titulo {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    color: var(--color-text-light);
}

.card-detalle__extra {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-detalle__extra__texto {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
}

.card-detalle__extra__icono {
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius-md);
    position: relative;
}

.card-detalle__extra__icono__fondo {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    z-index: 1;
    opacity: .2;
}

.card-detalle__extra__icono__icono {
    position: absolute;
    z-index: 2;
    top: 25%;
    left: 25%;
    font-size: var(--font-size-md);
}