
.card-producto {
    min-width: 320px;
    width: 98%;
    min-height: 136px;
    background: var(--color-card);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    padding: var(--padding-md);
    display: flex;
    gap: var(--padding-lg);
    margin: auto;
    cursor: var(--cursor);
    transition: all var(--animation-duration) var(--animation-timing-function);
}

.card-producto:hover {
    transition: all var(--animation-duration) var(--animation-timing-function);
    transform: scale(1.02);
}

.card-producto__foto {
    width: 100px;
    height: 100px;
    object-position: center;
    object-fit: cover;
}

.card-producto__informacion {
    display: grid;
    gap: var(--padding-md);
}

.card-producto__informacion__titulo {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
}

.card-producto__informacion__extra {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-regular);
}

.card-producto__informacion__extra span {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
}