.modal-content--cargando {
    width: 90%;
    max-width: 835px;
}

.contenedor-modal-cargando {
    width: 100%;
    padding: var(--padding-xl);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contenedor-modal-cargando__titulo {
    font-family: var(--font-titulo);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    line-height: 24px;
    text-align: center;
    margin-bottom: 5px;
}

.contenedor-modal-cargando__texto {
    font-family: var(--font);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-regular);
    line-height: 24px;
    text-align: center;
}