
.contenedor-informacion-venta {
    display: grid;
    padding: var(--padding-2xl);
    overflow-y: auto;
    max-height: calc(100% - 10px - (var(--padding-2xl) * 2));
}

.contenedor-informacion-venta:hover::-webkit-scrollbar {
    display: initial;
}

.contenedor-informacion-venta::-webkit-scrollbar {
    width: 3px;
    display: none;
}

.contenedor-informacion-venta::-webkit-scrollbar-thumb {
    background-color: var(--color-background-elements-active);
    border-radius: var(--border-radius-md);
}

.contenedor-informacion-venta__datos {
    display: flex;
    gap: 8px;
}

.contenedor-informacion-venta__datos__lateral {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
}