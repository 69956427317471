
.fondo-modal-detalles {
    background: var(--color-background-modal);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
}

.fondo-modal-detalles--vista {
    background: initial;
}


.fondo-modal-detalles--mostrar {
    visibility: visible;
    opacity: 1;
    transition: all 0.4s ease;
}

.modal-detalles {
    max-width: 536px;
    height: 100%;
    background: var(--color-card);
    position: absolute;
    overflow: hidden;
    transition: width 0.2s ease;
}

.modal-detalles--mostrar {
    width: 100%;
    transition: width 0.2s ease;
}

.modal-detalles--der {
    top: 0;
    right: 0;
}

.modal-detalles--izq {
    top: 0;
    left: 0;
}

.modal-detalles--vista {
    width: 80%;
    max-width: 1200px;
    height: fit-content;
    margin: 30px auto;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-medium);
    position: initial;
    overflow: hidden;
    transition: width 0.2s ease;
}

.modal-detalles__cabezera {
    width: 100%;
    padding: var(--padding-2xl);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
}

.modal-detalles__cabezera__titulo {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
}

.modal-detalles__cabezera__icono {
    font-size: var(--font-size-xl);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.modal-detalles__cabezera__icono:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}