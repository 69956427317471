
.container-input-count {
    display: grid;
    gap: 5px;
    width: 90%;
    height: fit-content;
}

.container-input-count .container-input-count__label {
    font-size: 16px;
    font-weight: var(--font-weight-regular);
    line-height: 26px;
}

.container-input-count .container-input-count__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: var(--padding-sm);
    border-radius: var(--border-radius-sm);
    background-color: var(--color-negativo);
}

.container-input-count__input .button {
    color: var(--color-primario);
}

.container-input-count__input .container-input-count__input__value,
.container-input-count__input .container-input-count__input__input {
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    line-height: 24px;
    width: 100px;
    text-align: center;
    cursor: var(--cursor);
}