
.contenedor-scroll {
    width: 100%;
    padding: var(--padding-sm);
    display: grid;
    gap: var(--padding-md);
    overflow-y: scroll;
    overflow-x: hidden;
}

.contenedor-scroll--md {
    max-height: 500px;
}

.contenedor-scroll:hover::-webkit-scrollbar {
    display: initial;
}

.contenedor-scroll::-webkit-scrollbar {
    width: 3px;
    display: none;
}

.contenedor-scroll::-webkit-scrollbar-thumb {
    background-color: var(--color-background-elements-active);
    border-radius: var(--border-radius-md);
}