
.contenedor-firma {
    width: 100%;
    padding: var(--padding-md);
    display: grid;
    gap: 8px;
}

.contenedor-firma .contenedor-firma__titulo {
    font-family: var(--font-titulo);
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    line-height: 20px;
}

.contenedor-firma .contenedor-firma__sub-titulo {
    font-family: var(--font);
    font-size: 14px;
    font-weight: var(--font-weight-rgular);
    line-height: 20px;
}

.contenedor-firma .contenedor-firma__firma {
    background-color: var(--color-background-body);
    border-radius: var(--border-radius-sm);
    padding: var(--padding-md);
    display: grid;
    gap: 5px;
}

.contenedor-firma__firma .contenedor-firma__firma__acciones {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
}

.contenedor-firma__firma__acciones .contenedor-firma__firma__acciones__boton {
    cursor: var(--cursor);
    color: var(--color-primario);
    opacity: .5;
    text-align: right;
    font-family: var(--font);
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s ease-in-out;
    width: fit-content;
    height: fit-content;
    place-self: center;
}

.contenedor-firma__firma__acciones__boton:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.contenedor-firma__firma .contenedor-firma__firma__linea {
    width: 50%;
    place-self: center;
    border-bottom: 1px solid var(--color-border);
}