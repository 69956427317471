:root {
    --max-height-tabla-productos: 550px
}

.contenedor-tabla-productos {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--padding-md);
}

.contenedor-tabla-productos__tabla {
    width: 100%;
    max-width: 1250px;
    height: 100%;
    padding: var(--padding-md);
    display: grid;
    grid-template-rows: auto 1fr;
    gap: var(--padding-xl);
}

.contenedor-tabla-productos__tabla__header {
    width: 100%;
    display: flex;
    gap: var(--padding-lg);
    align-items: center;
}

.contenedor-tabla-productos__tabla__grid {
    width: 100%;
    height: fit-content;
    max-height: var(--max-height-tabla-productos);
    padding: var(--padding-sm);
    display: grid;
    gap: var(--padding-md);
    grid-template-columns: repeat(auto-fit, minmax(320px, auto));
    grid-template-rows: max-content;
    overflow-y: auto;
    overflow-x: hidden;
}

.contenedor-tabla-productos__tabla__grid__mensaje {
    font-size: var(--font-titulo);
    font-size: var(--font-size-lg);
    text-align: center;
    font-weight: var(--font-weight-bold);
    color: var(--color-border);
}

.contenedor-tabla-productos__tabla__grid::-webkit-scrollbar {
    width: 3px;
}

.contenedor-tabla-productos__tabla__grid::-webkit-scrollbar-thumb {
    background-color: var(--color-background-elements-active);
    border-radius: var(--border-radius-md);
}