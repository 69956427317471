
.tabla-conceptos {
    width: 100%;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
}

/*------------*\
 # Encabezados
\*------------*/
.tabla-conceptos__cabezera {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    border-bottom: inherit;
}

/*------------*\
 # Cuerpo
\*------------*/
.tabla-conceptos__cuerpo {
    display: grid;
    gap: 0px;
}

/*------------*\
 # Total
\*------------*/
.tabla-conceptos__total {
    display: grid;
    gap: 0px;
}