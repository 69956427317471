
.membrete {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.membrete__logo {
    width: 100px;
    height: 100px;
    object-position: center;
    object-fit: cover;
}

.membrete__informacion {
    width: 100%;
    text-align: center;
    font-size: var(--font-size-md);
    display: grid;
    gap: 4px;
    text-transform: capitalize;
}

.membrete__informacion__titulo {
    text-align: inherit;
    margin-bottom: 4px;
    text-transform: inherit;
}

.membrete__informacion__sub-titulo {
    text-align: inherit;
    font-size: inherit;
    text-transform: inherit;
}

.membrete__informacion__titulo--md {
    font-size: var(--font-size-lg);
}

.membrete__informacion__sub-titulo--md {
    font-size: var(--font-size-md);
}

.membrete__informacion__titulo--sm {
    font-size: var(--font-size-md);
}

.membrete__informacion__sub-titulo--sm {
    font-size: var(--font-size-xs);
}

.extra-membrete__info {
    width: 100%;
    height: fit-content;
    text-align: right;
    color: var(--color-primario);
    text-transform: capitalize;
}

.extra-membrete__info--sm {
    font-size: var(--font-size-xs);
}

.extra-membrete__info--md {
    font-size: var(--font-size-md);
}