
.seccion-categorias {
    width: 200px;
    height: calc(var(--max-height-tabla-productos) + 136px);
    padding: var(--padding-md);
    display: flex;
    flex-direction: column;
    gap: var(--padding-lg);
    overflow-y: scroll;
    overflow-x: hidden;
}

.seccion-categorias__sin-categorias {
    width: 96%;
    margin: 0px auto;
    background: var(--color-primario);
    color: var(--color-card);
    text-align: center;
    padding: var(--padding-sm);
    border-radius: var(--border-radius-md);
}

.seccion-categorias__seccion-titulo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seccion-categorias__icon-limpiar-filtro {
    cursor: var(--cursor);
}

.seccion-categorias__titulo {
    font-family: var(--font-titulo);
    font-size: var(--font-size-lg);
}

.seccion-categorias__contenido {
    width: 100%;
    display: grid;
    gap: var(--padding-sm);
}

.seccion-categorias__contenido__sub-categorias {
    display: none;
    gap: 5px;
    padding: 0px;
    height: 0px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.seccion-categorias__contenido__sub-categorias--visible {
    padding: var(--padding-sm);
    padding-left: var(--padding-lg);
    height: fit-content;
    opacity: 1;
    display: grid;
}

/* # SCROLL */
.seccion-categorias:hover::-webkit-scrollbar {
    display: initial;
}

.seccion-categorias::-webkit-scrollbar {
    width: 3px;
    display: none;
}

.seccion-categorias::-webkit-scrollbar-thumb {
    background-color: var(--color-background-elements-active);
    border-radius: var(--border-radius-md);
}