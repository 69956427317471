
.boton-icono {
    width: 24px;
    height: 24px;
    border-radius: var(--border-radius-circle);
    background: var(--color-card);
    box-shadow: var(--box-shadow-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: var(--cursor);
    transition: all 0.3s ease-in-out;
}

.boton-icono:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
}

.boton-icono__icono {
    color: var(--color-primario);
    font-size: var(--font-size-xs);
}