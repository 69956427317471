
.seccion-informacion {
    width: 100%;
    height: fit-content;
    padding: var(--padding-xl);
    display: grid;
    gap: 16px;
    overflow-y: auto;
    max-height: calc(100% - var(--padding-2xl) * 2 - var(--padding-xl) - 8px);
}

.seccion-informacion:hover::-webkit-scrollbar {
    display: initial;
}

.seccion-informacion::-webkit-scrollbar {
    width: 3px;
    display: none;
}

.seccion-informacion::-webkit-scrollbar-thumb {
    background-color: var(--color-background-elements-active);
    border-radius: var(--border-radius-md);
}

.seccion-informacion__principal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.seccion-informacion__principal__datos {
    width: 100%;
    display: grid;
    gap: 16px;
}

.seccion-informacion__principal__datos h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
}

.seccion-informacion__principal__datos span {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.seccion-informacion__principal__datos p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.seccion-informacion__acciones {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}