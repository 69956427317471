
.busquedad-contenedor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.busquedad-contenedor .busquedad-contenedor__select-busquedad {
    flex-grow: 3;
}