
.fila-concepto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    border-bottom: 1px solid var(--color-border);
}

.fila-concepto--fondo {
    background: var(--color-background-opacity);
}

.fila-concepto--sin-borde {
    border-bottom: 1px solid transparent;
}