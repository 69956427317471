
.columna-concepto {
    width: 100%;
    height: 100%;
    padding: var(--padding-sm) var(--padding-md);   
    border-right: 1px solid var(--color-border);
    flex-basis: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.columna-concepto__normal {
    font-family: var(--font);
    font-size: 13px;
    line-height: 16px;
    font-weight: var(--font-weight-regular);
}

.columna-concepto__cabecera {
    font-family: var(--font-titulo);
    font-size: 14px;
    line-height: 16px;
    font-weight: var(--font-weight-bold);
}

.columna-concepto--boton {
    cursor: var(--cursor);
}

.columna-concepto--boton:hover {
    color: var(--color-text-primario) !important;
}

.columna-concepto--alineacion-izq {
    justify-content: flex-start;
}

.columna-concepto--alineacion-der {
    justify-content: flex-end;
}

.columna-concepto--espaciado {
    justify-content: space-between;
}

.tabla-conceptos__cabezera__celda--sin-border {
    border-right: 1px solid transparent;
}

.columna-concepto--sm {
    flex-grow: 1;
}

.columna-concepto--md {
    flex-grow: 2;
}

.columna-concepto--lg {
    flex-grow: 3;
}

.columna-concepto--xl {
    flex-grow: 4;
}